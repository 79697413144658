














import Vue from 'vue';
import QuizGroupApi from '@/apis/QuizGroupApi';

export default Vue.extend({
  data() {
    return {
      autoSaveTimerId: -1,
    };
  },
  computed: {
    quizGroupName: {
      get() {
        return this.$store.state.currentQuizGroup.metadata.name;
      },
      set(value) {
        this.$store.commit('updateQuizGroupName', value);
      },
    },
  },
  methods: {
    /**
     *  Update inputed name to backend database after stopped typing for more than 1 second
     */
    autoSaveQuizGroupName() {
      clearTimeout(this.autoSaveTimerId);

      this.autoSaveTimerId = setTimeout(() => {
        const { uuid, metadata } = this.$store.state.currentQuizGroup;
        QuizGroupApi.update(uuid, { name: metadata.name }).catch((error) => {
          if (error.response.status === 409) {
            this.$notify({
              type: 'error',
              title: '更改名稱失敗',
              text: '此名稱已經被同資料夾內的其他檔案使用',
            });
          }
        });
      }, 1000);
    },
  },
});
