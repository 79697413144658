<template>
  <div id="app">
    <navbar />
    <loading :active.sync="$store.state.isLoading" />
    <notifications position="bottom center" :ignore-duplicates="true" />
    <notifications v-if="debugMode" group="debug" position="bottom left" :duration="-1" />
    <router-view />
  </div>
</template>

<script>

import navbar from '@/components/navbar.vue';

export default {
  components: {
    navbar,
  },
  computed: {
    debugMode() {
      return this.$store.state.debugMode;
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
