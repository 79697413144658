import api from '@/apis/BaseApi';
import { UpdateQuizGroupModel, QuizGroupModel } from '@/apis/models/QuizGroupModel';
import i18n from '@/i18n';

export default class QuizGroupApi {
  /**
   * Get the detailed information of a quiz group
   * including all quizzes in the quiz group
   *
   * @param quizGroupId quiz group id
   */
  static async retrieve(quizGroupId: string): Promise<QuizGroupModel> {
    const result = await api.get(`workspace/quiz-groups/${quizGroupId}`);
    return result.data;
  }

  /**
   * Create a new QuizGroup for a workspace
   *
   */
  static async create(parentFolderId: string): Promise<QuizGroupModel> {
    const body = {
      name: i18n.t('未命名題組'),
      parentId: parentFolderId,
    };
    const result = await api.post('workspace/quiz-groups/', body);
    return result.data;
  }

  /**
   * Update QuizGroup by patch object
   *
   * @param quizGroupId
   * @param patch
   */
  static async update(quizGroupId: string, patch: UpdateQuizGroupModel): Promise<void> {
    const body = {
      ...patch,
    };

    await api.post(`workspace/quiz-groups/${quizGroupId}`, body);
  }
}
