






import Vue from 'vue';

/**
 * Enable debug mode if a user clicks {triggerThreshold} times in {timeout} seconds
 */
export default Vue.extend({
  name: 'DebugToggler',
  data() {
    return {
      clickCount: 0,
      triggerThreshold: 6, // click 6 times to trigger
      timeout: 3, // in seconds
      timeoutId: -1,
    };
  },
  computed: {
    debugMode(): boolean {
      return this.$store.state.debugMode;
    },
  },
  methods: {
    debugModeToggler() {
      this.clickCount += 1;
      if (this.clickCount >= this.triggerThreshold) {
        this.$store.commit('toggleDebugMode');

        this.$nextTick(() => {
          this.$notify({
            type: 'info',
            title: (this.debugMode) ? 'Debug mode is ON' : 'Debug mode is OFF',
          });
        });
        clearTimeout(this.timeoutId);
        this.clickCount = 0;
      } else {
        clearTimeout(this.timeoutId);
        setTimeout(() => {
          this.clickCount = 0; // reset click count if timeout
        }, this.timeout * 1000);
      }
    },
  },
});
