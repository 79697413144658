










































































import Vue from 'vue';
import QuizGroupNameInput from '@/components/quiz_group/QuizGroupNameInput.vue';
import Auth from '@/libs/auth';
import DebugToggler from '@/components/system/DebugToggler.vue';
import { Location } from 'vue-router/types/router.d';
import LanguageSelector from '@/components/system/LanguageSelector.vue';

interface NavbarLocation {
  label: string;
  location: Location;
}

export default Vue.extend({
  components: {
    QuizGroupNameInput,
    DebugToggler,
    LanguageSelector,
  },
  computed: {
    links(): NavbarLocation[] {
      if (!this.$store.state.currentWorkspace) {
        return [];
      }
      return [
        {
          location: {
            name: 'QuizGroups',
            params: {
              folderId: this.$store.state.currentWorkspace.quizFolders.local,
            },
          },
          label: this.$t('題庫').toString(),
        },
        {
          location: {
            name: 'MemberGroups',
            params: {
              folderId: this.$store.state.currentWorkspace.memberFolders.local,
            },
          },
          label: this.$t('班級').toString(),
        },
        {
          location: {
            name: 'QuizEvents',
            params: {
              folderId: this.$store.state.currentWorkspace.eventFolders.local,
            },
          },
          label: this.$t('測驗').toString(),
        },
      ];
    },
    username(): string {
      if (this.$store.state.profile) {
        return this.$store.state.profile.username;
      }

      return 'unknown';
    },
    homepageLocation(): Location {
      if (this.$route.name !== 'Login' && this.$store.state.currentWorkspace) {
        return {
          name: 'QuizGroups',
          params: {
            folderId: this.$store.state.currentWorkspace.quizFolders.local,
          },
        };
      }
      return { name: 'Login' };
    },
    isLogin(): boolean {
      return Boolean(this.$store.state.profile);
    },
  },
  methods: {
    logout() {
      Auth.logout();
    },
  },
});
