import Vue from 'vue';
import VueRouter, { RawLocation, Route, RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/',
    alias: ['/login'],
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/under-construction',
    name: 'UnderConstruction',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/UnderConstruction.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/Register.vue'),
  },
  {
    path: '/finish-register',
    name: 'FinishRegister',
    component: () => import('@/views/FinishRegister.vue'),
  },
  {
    path: '/verify',
    name: 'RegisterVerification',
    component: () => import('@/views/RegisterVerification.vue'),
  },
  {
    path: '/manager',
    name: 'Manager',
    component: () => import(/* webpackChunkName: "about" */ '../views/Manager.vue'),
    children: [
      {
        path: 'workspace',
        name: 'Workspace',
        component: () => import('../views/Workspace.vue'),
        children: [
          {
            path: 'quizgroups/local/:folderId',
            name: 'QuizGroups',
            component: () => import('../views/WorkspaceBrowser.vue'),
          },
          {
            path: 'quizgroups/share/:folderId',
            name: 'QuizGroupSoftLinks',
            component: () => import('../views/WorkspaceBrowser.vue'),
          },
          {
            path: 'quizgroups/trash/:folderId',
            name: 'QuizGroupTrash',
            component: () => import('../views/WorkspaceBrowser.vue'),
          },
          {
            path: 'membergroups/local/:folderId',
            name: 'MemberGroups',
            component: () => import('../views/WorkspaceBrowser.vue'),
          },
          {
            path: 'membergroups/share/:folderId',
            name: 'MemberGroupSoftLinks',
            component: () => import('../views/WorkspaceBrowser.vue'),
          },
          {
            path: 'membergroups/trash/:folderId',
            name: 'MemberGroupTrash',
            component: () => import('../views/WorkspaceBrowser.vue'),
          },
          {
            path: 'quizevents/local/:folderId',
            name: 'QuizEvents',
            component: () => import('../views/WorkspaceForEvents.vue'),
          },
          {
            path: 'quizevents/share/:folderId',
            name: 'QuizEventSoftLinks',
            component: () => import('../views/WorkspaceForEvents.vue'),
          },
          {
            path: 'quizevents/trash/:folderId',
            name: 'QuizEventTrash',
            component: () => import('../views/WorkspaceForEvents.vue'),
          },
          // {
          //   path: 'authority',
          //   name: 'Authority',
          //   component: () => import('@/views/Authority.vue'),
          // },
        ],
      },
      {
        path: 'quizgroup-maker',
        name: 'QuizGroupMaker',
        component: () => import('@/views/QuizGroupMaker.vue'),
      },
      {
        path: 'quizgroup-preview',
        name: 'QuizGroupPreview',
        component: () => import('../views/QuizGroupPreview.vue'),
      },
      {
        path: 'membergroup-maker',
        name: 'MemberGroupMaker',
        component: () => import('@/views/MemberGroupMaker.vue'),
      },
      {
        path: 'membergroup-preview',
        name: 'MemberGroupPreview',
        component: () => import('../views/MemberGroupPreview.vue'),
      },
      {
        path: 'quizevent-maker',
        name: 'QuizEventMaker',
        component: () => import('../views/QuizEventMaker.vue'),
      },
      {
        path: 'quizevent-preview',
        name: 'QuizEventPreview',
        component: () => import('../views/QuizEventPreview.vue'),
      },
    ],
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('@/views/Error.vue'),
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

/**
 * Ignore NavigationDuplicated error
 *
 * @see https://stackoverflow.com/a/67102038/5731332
 */
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: RawLocation): Promise<Route> {
  return new Promise((resolve, reject) => {
    originalPush.call(this, location, () => {
      // on complete

      resolve(this.currentRoute);
    }, (error) => {
      // on abort

      // only ignore NavigationDuplicated error
      if (error.name === 'NavigationDuplicated') {
        resolve(this.currentRoute);
      } else {
        reject(error);
      }
    });
  });
};

export default router;
