import { ProfileModel } from '@/apis/models/AccountModel';
import { MemberGroupPreviewModel } from '@/apis/models/MemberGroupModel';
import { QuizEventPreviewModel } from '@/apis/models/QuizEventModel';
import { QuizGroupModel } from '@/apis/models/QuizGroupModel';
import { WorkspaceDataModel } from '@/apis/models/WorkspaceModel';
import { StageOptionModel } from '@/libs/options';
import { WorkspaceFolderModel } from '@/apis/models/WorkspaceObjectModel';
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import { Location } from 'vue-router';
import { QuizInstanceModel, QuizTemplateModel, SolutionImage } from '@/apis/models/QuizModel';

Vue.use(Vuex);

const getDefaultState = () => ({
  focusObject: { options: {} },
  localOptions: {},
  globalOptions: [] as StageOptionModel[],
  workspaceList: [] as WorkspaceDataModel[],
  currentWorkspace: null as WorkspaceDataModel | null,
  currentQuizGroup: {} as QuizGroupModel,
  currentMemberGroup: {} as MemberGroupPreviewModel,
  currentQuizEvent: {} as QuizEventPreviewModel,
  homepageRoute: {
    name: '',
  } as Location,
  isLoading: false,
  debugMode: false,
  stageAnswer: [] as string[],
  stagePendingTaskCount: 0,
  stageHighlightLabel: null as null | string,
  profile: null as ProfileModel | null,
  currentFolder: null as WorkspaceFolderModel | null,
  childrenFolderNum: 0 as number,
  editPermission: false, // 關鍵字'enterGroup'，目前只有在shardObjectCard裡面的檢視者是false，其他都是true (groupEvent除外)
  cardDisplayMode: 'list-mode', // 'pic-mode'
  showGuidedTour: true,
  autoConfirm: false, // currentMemberGroup.autoConfirm
  solutionImage: {
    url: '',
    width: 0,
    height: 0,
  } as SolutionImage,
  solutionContent: '',
});

export default new Vuex.Store({
  state: getDefaultState(),
  mutations: {
    updateCurrentFolder(state, folder: WorkspaceFolderModel) {
      state.currentFolder = folder;
    },

    resetCurrentFolder(state) {
      state.currentFolder = null;
    },

    // --------

    onFocusObject(state, object) {
      state.focusObject = object;
    },

    // addLocalOption(state, option: ContentModel) {
    //   state.localOptions.push(option);
    // },
    setLocalOption(state, content) {
      state.localOptions = content;
    },
    resetLocalOption(state) {
      state.localOptions = {};
    },
    addGlobalOption(state, option: StageOptionModel) {
      state.globalOptions.push(option);
    },
    setGlobalOption(state, options: StageOptionModel[]) {
      state.globalOptions = options;
    },

    /**
     *
     * Usage:
     *
      this.$store.commit('editGlobalOption', {
        optionLabel: 'label',
        newValue: value,
      });
     */
    editGlobalOption(state, { optionLabel, newValue }) {
      const optionToEdit = state.globalOptions.find((option: StageOptionModel) => option.label === optionLabel);

      if (optionToEdit) {
        optionToEdit.value = newValue;
      }
    },

    updateWorkspaceList(state, workspaceList: WorkspaceDataModel[]) {
      state.workspaceList = workspaceList;
    },

    changeWorkspace(state, workspace: WorkspaceDataModel) {
      state.currentWorkspace = workspace;
    },

    changeQuizGroup(state, group: QuizGroupModel) {
      state.currentQuizGroup = group;
    },
    // ------------暫加的
    saveProfile(state, profile: ProfileModel) {
      state.profile = profile;
    },
    changeMemberGroup(state, group) {
      state.currentMemberGroup = group;
    },
    changeQuizEvent(state, event) {
      state.currentQuizEvent = event;
    },
    // -----------到這裡------
    updateQuizGroupName(state, name: string) {
      state.currentQuizGroup.metadata.name = name;
    },

    updateLoading(state, status) {
      state.isLoading = status;
    },

    updateStageAnswer(state, newAnswer: string[]) {
      state.stageAnswer = newAnswer;
    },

    addStagePendingTask(state) {
      state.stagePendingTaskCount += 1;
    },

    finishStagePendingTask(state) {
      state.stagePendingTaskCount -= 1;
    },

    cleanStagePendingTask(state) {
      state.stagePendingTaskCount = 0;
    },

    updateStageHighlightLabel(state, label: string) {
      state.stageHighlightLabel = label;
    },

    resetStage(state) {
      state.stageAnswer = [];
      state.localOptions = {};
      state.stagePendingTaskCount = 0;
    },

    toggleDebugMode(state) {
      state.debugMode = !state.debugMode;
    },

    resetState(state) {
      Object.assign(state, getDefaultState());
    },

    updateEditPermission(state, status) {
      state.editPermission = status;
    },

    changeCardDisplayMode(state, status) {
      state.cardDisplayMode = status;
    },

    updateGuidedTour(state, status) {
      state.showGuidedTour = status;
    },
    updateAutoConfirm(state, status) {
      state.autoConfirm = status;
    },
    updateSolutionImage(state, status) {
      state.solutionImage = status;
    },
    updateSolutionContent(state, status) {
      state.solutionContent = status;
    },
  },
  actions: {
  },
  modules: {
  },

  plugins: [new VuexPersistence().plugin],
});
