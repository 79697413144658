import vm from '@/main';

export default class Auth {
  static loginCheck(): boolean {
    if (vm.$cookies.isKey('token')) return true;

    this.logout();
    return false;
  }

  static logout() {
    if (vm.$cookies.isKey('token')) {
      vm.$cookies.remove('token');
    }
    vm.$store.commit('resetState');
    vm.$router.push('/login');
  }
}
