





















import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      langs: [
        {
          label: '繁體中文',
          code: 'zh-TW',
        },
        {
          label: 'English',
          code: 'en',
        },
      ],
    };
  },
});
